<template>
  <div class="container-fluid" id="printArea">
    <CRow class="pb-3">
      <CCol>
        <h4 class="text-uppercase">Growth Report</h4>
      </CCol>
    </CRow>
    <CCard>
      <form v-on:submit.prevent="filter(search)" class="d-print-none">
        <CRow>
          <CCol cols="8">
            <CRow>
              <CCol cols="2">
                <div class="form-group">
                  <select
                    name="status"
                    v-model="search.type"
                    class="form-control"
                    required
                  >
                    <option value="">Type</option>
                    <option value="YEARLY">Yearly</option>
                    <option value="MONTHLY">Monthly</option>
                  </select>
                </div>
              </CCol>
              <CCol cols="2" v-if="search.type === 'MONTHLY'">
                <div class="form-group">
                  <select
                    name="status"
                    v-model="search.year"
                    class="form-control"
                    required
                  >
                    <option value>Select Year</option>
                    <option v-for="year in years" :key="year" :value="year">{{
                      year
                    }}</option>
                  </select>
                </div>
              </CCol>

              <CCol cols="1">
                <button type="submit" class="btn btn-success w-100">
                  Search
                </button>
              </CCol>
            </CRow>
          </CCol>
          <CCol cols="4" class="text-right">
            <CCol sm="12" class="text-right d-print-none">
              <a @click="print()" class="print-btn" href="javascript:void(0)"
                ><i class="fa fa-print fa-3x"></i></a
              >
            </CCol>
          </CCol>
        </CRow>
      </form>
      <CRow>
        <CCol sm="12">
          <CChartLine class="d-print-none" :datasets="growthChartData" :labels="growthChartLabel" />
          <img v-if="canvasUrl" :src="canvasUrl" alt="consumption chart" class="d-none d-print-block w-100">
        </CCol>
      </CRow>
    </CCard>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { CChartLine } from "@coreui/vue-chartjs";

export default {
  name: "GrowthReport",
  data: () => {
    return {
      search: {
        year: "",
        type: "YEARLY",
      },
      canvasUrl: null,
      serverErrors: [],
      showDismissibleAlert: false,
      startYear: 2016,
      breadcrumbs: [{text:"Home", path:"/dashboard", isActive: false}, {text:"Reports", path:"/reports", isActive: false}, {text:"Growth", path:"", isActive: true}]
    };
  },
  components: { CChartLine },
  computed: {
    ...mapGetters("Report", ["growthChartData", "growthChartLabel"]),
    years() {
      let data = [];
      let cYear = new Date().getFullYear();
      for (var i = cYear; i >= this.startYear; i--) {
        data.push(i);
      }
      return data;
    },
  },

  methods: {
    filter(search) {
      if (search) {
        this.$store.dispatch("Report/growthReport", search);
      }
    },
    print() {
      let canvas = document.querySelector('canvas');
      this.canvasUrl = canvas.toDataURL();

      setTimeout(() => {
        this.printSetup();
      }, 50);
    },
    printSetup() {
      // Get HTML to print from element
      const prtHtml = document.getElementById("printArea").innerHTML;
      // Get all stylesheets HTML
      let stylesHtml = "";
      for (const node of [
        ...document.querySelectorAll('link[rel="stylesheet"], style'),
      ]) {
        stylesHtml += node.outerHTML;
      }

      // Open the print window
      const WinPrint = window.open(
        "",
        "",
        "left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0"
      );

      WinPrint.document.write(`<!DOCTYPE html>
<html>
  <head>
    <title>Growth Report</title>
    ${stylesHtml}
  </head>
  <body>
    ${prtHtml}
  </body>
</html>`);

      WinPrint.document.close();
      WinPrint.focus();
      WinPrint.addEventListener("load", () => {
        setTimeout(() => {
          WinPrint.print();
          WinPrint.close();
        }, 200);
      });
    },
  },
  mounted() {
    this.$store.dispatch("Report/growthReport", this.search);
  },
 created() {
    this.$store.dispatch("Config/setBreadCrumb", this.breadcrumbs);
  },
};
</script>

<style scoped>
.card {
    border: none;
    padding: 15px 15px 0;
}
</style>

<style lang="scss" scoped>
div {
  h4 {
    font-size: 15px;
    font-weight: bold !important;
    color: #000;
  }
}
.print-btn {
  color: #000;
}
</style>
